import React, { useEffect, useMemo, useState } from 'react'
import { ASSET_URL, fee } from '../../../../helper/api_url'
import { TableResponsive } from '../../../../components/common/TableResponsive';
import { differenceInMonths, parseISO } from 'date-fns';

function StudentFees({course_id,activeTab}) {
    const [duesList,setDuesList] = useState([]);
    const [tableLoading,setTableLoading] = useState(false);
    useEffect(()=>{
        setTableLoading(true);
        if(course_id && activeTab === 3) fee.courseDuesList(course_id)
        .then(res=>setDuesList(res.data.fee_dues))
        .catch(e=>console.error(e.response?e.response.data.message:e.message))
        .finally(()=>setTableLoading(false));
    },[activeTab])
    const columns = useMemo(()=>[
        {Header: "SrNo",accessor: "id", Cell: cell => {return (<p className="">{cell.row.index+1}</p>)},HeaderClass:'text-center', DataClass:'text-center'},
        {
            Header: "Name",
            accessor: "first_name",
            HeaderClass:'',
            DataClass:'',
            Cell:(cell)=> {
                const imageUrl =ASSET_URL + cell.row.original.avatar;
                return (<span> <img className="me-2 rounded-circle header-profile-user" style={{objectFit:'cover'}} src={imageUrl} alt="User Avatar" />{cell.row.original.first_name} {cell.row.original.last_name}</span>)
            }
        },
        {Header: "Phone",accessor: "phone",HeaderClass:'text-center', DataClass:'text-center'},
        {
            Header:'Dues',
            HeaderClass:'text-center', 
            DataClass:'text-center',
            Cell:cell=>{
                const row = cell.row.original;
                const dues = row.due_fee.reduce((acc,index)=>{
                    const paid_amount = index.fees_payment?.reduce((total,txn)=>total+(parseFloat(txn.paid_amt)),0)
                    if(index.frequency_type === 'monthly'){
                        const parsedGivenDate = parseISO(index.last_payment_date);
                        const currentDate = new Date();
                        const monthsPassed = differenceInMonths(currentDate, parsedGivenDate) + 1;
                        return acc + parseFloat(index.imposed_amount)*monthsPassed - paid_amount;
                    }
                    return acc + parseFloat(index.imposed_amount) - paid_amount;
                },0)
                return dues>=0?dues:0;
            }
        }
    ],[])
    return (
        <TableResponsive isLoading={tableLoading} columns={columns} data={duesList} />
    )
}

export default StudentFees