import React, { useEffect, useMemo, useState } from 'react'
import { ASSET_URL, course } from '../../../../helper/api_url';
import { swal } from '../../../../helper/swal';
import { TableResponsive } from '../../../../components/common/TableResponsive';

function StudentList(props) {
    const {course_id,activeTab} = props;
    const [studentList,setStudentList] = useState([]);
    const [tableLoading,setTableLoading] = useState(false);
    useEffect(()=>{
        setTableLoading(true)
        if(course_id && activeTab === 0){
            course.students(course_id).then(res=>setStudentList(res.data.student))
            .catch(e=>swal.error(e.response?e.response.data.message:e.message))
            .finally(()=>setTableLoading(false))
        }
    },[activeTab])
    const columns = useMemo(()=>[
        {Header: "SrNo",accessor: "id", Cell: cell => {return (<p className="">{cell.row.index+1}</p>)},HeaderClass:'text-center', DataClass:'text-center'},
        {
            Header: "Name",
            accessor: "first_name",
            HeaderClass:'',
            DataClass:'',
            Cell:(cell)=> {
                const imageUrl =ASSET_URL + cell.row.original.avatar;
                return (<span> <img className="me-2 rounded-circle header-profile-user" style={{objectFit:'cover'}} src={imageUrl} alt="User Avatar" />{cell.row.original.first_name} {cell.row.original.last_name}</span>)
            }
        },
        {Header: "Gender",accessor: "gender", Cell: cell => {return (<p className="">{cell.row.original.gender.toUpperCase()}</p>)},HeaderClass:'text-center', DataClass:'text-center'},
        {Header: "DOB",accessor: "dob",HeaderClass:'text-center', DataClass:'text-center'},
        {Header: "Email",accessor: "email",HeaderClass:'text-center', DataClass:'text-center'},
        {Header: "Phone",accessor: "phone",HeaderClass:'text-center', DataClass:'text-center'},
    ],[])
    return (
        <TableResponsive isLoading={tableLoading} columns={columns} data={studentList} />
    )
}

export default StudentList