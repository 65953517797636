import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../components/common/BreadCrumb'
import { Card, CardBody, CardHeader, Col, Row, Button } from "react-bootstrap";
import { TableResponsive } from "../../../components/common/TableResponsive";
import CustomSelect from '../../../components/CustomSelect';
import { Link } from 'react-router-dom';
import { attendance, course } from '../../../helper/api_url';
import { swal } from '../../../helper/swal';
import { useDispatch } from 'react-redux';
import { setPreloader } from '../../../features/Ui/uiSlice';


export const AttendanceList = () => {
    const [courses, setCourses] = useState([]);
    const currentDate = new Date();
    const [generatedColumns,setGeneratedColumns] = useState([]);
    const [tableData,setTableData] = useState([]);
    const dispatch = useDispatch();
    const MONTHS = [
        {days:'31',value:0,label:"January"},
        {days:'28',value:1,label:"February"},
        {days:'31',value:2,label:"March"},
        {days:'30',value:3,label:"April"},
        {days:'31',value:4,label:"May"},
        {days:'30',value:5,label:"June"},
        {days:'31',value:6,label:"July"},
        {days:'31',value:7,label:"August"},
        {days:'30',value:8,label:"September"},
        {days:'31',value:9,label:"October"},
        {days:'30',value:10,label:"November"},
        {days:'31',value:11,label:"December"}
    ];
    useEffect(() => {
        course.list().then(r => setCourses(r.data.course)).catch(e => console.error(e));
    }, [])
    const generateDynamicColumns = (month)=>{
        const daysInMonth = MONTHS.filter(i=>i.value === month)[0].days;
        const generatedColumns = [];
        for (let day = 1; day <= daysInMonth; day++) {
            const column = {
                Header: day.toString(),
                HeaderClass: (currentDate.getDate() == day && currentDate.getMonth() == month)? 'text-center bg-soft-warning':'text-center',
                DataClass: (currentDate.getDate() == day && currentDate.getMonth() == month)? 'text-center bg-soft-warning':'text-center',
                Cell: cell => {
                    const attendanceData = cell.row.original.attendance.find(i => {
                        const date = new Date(i.attendance_date);
                        return date.getDate() === day;
                    });
                    return attendanceData 
                    ?(<span className={(attendanceData.status == 'P'?'text-success':(attendanceData.status == 'A'?'text-danger':(attendanceData.status == 'L'?'text-warning':'')))} >{attendanceData.status} </span>) 
                    : (currentDate.getMonth() > month) ? 'N/A':((currentDate.getDate() >= day && (currentDate.getMonth() == month)?'N/A':'-'));
                }
            };
            generatedColumns.push(column);
        }
        return generatedColumns;
    }
    const handleFetchFormSubmit = e => {
        dispatch(setPreloader({ loader: true, message: "Fetching Attendance Please wait ..." }));
        e.preventDefault();
        const formData = new FormData(e.target);
        const month = parseInt(formData.get('month'))+1;
        const class_id = formData.get('class_id');
        if(!(class_id && month)){
            swal.error('Opppsss...!','Please select Month and Batch')
            return
        }
        attendance.list_by_batch(class_id,month,currentDate.getFullYear())
        .then(res=>{
            setGeneratedColumns(generateDynamicColumns(month-1))
            console.log(generatedColumns);
            setTableData(res.data.attendance);
        })
        .catch(e=>swal.error(e.response ? e.response.data.message:e.message))
        .finally(()=>dispatch(setPreloader({ loader: false, message: "" })))
    }
    return (
        <>
            <BreadCrumb title="Attendance List" prevPage="Attendance" prevPath="/attendance" />
            <Row>
                <Col xs={12}>
                    <Card>
                        <CardHeader className="d-flex align-items-center justify-content-between">
                            <h5 className="card-title mb-0">Attendance List</h5>
                            <Link to="/attendance" className='btn btn-soft-success add-btn waves-effect me-2'>
                                <i className="ri-contacts-fill pt-1"></i> New Attendance
                            </Link>
                        </CardHeader>
                        <CardBody className="">
                            <form onSubmit={handleFetchFormSubmit}>
                                <Row className='my-3'>
                                    <div className="col-5 mt-2">
                                        <div>
                                            <label htmlFor="date" className="form-label">Month</label>
                                            <CustomSelect defaultValue={MONTHS.filter(i=>i.value === currentDate.getMonth())} name='month' id='date' options={MONTHS} />
                                        </div>
                                    </div>
                                    <div className="col-5 mt-2">
                                        <div>
                                            <label htmlFor="select_class_input" className="form-label">Select A Class</label>
                                            <CustomSelect id="select_class_input" name="class_id" isSearchable options={courses?.map(i => ({ label: i.name, value: i.id }))} />
                                        </div>
                                    </div>
                                    <div className="col-2 mt-2">
                                        <label htmlFor="fetch" style={{opacity:0}} className="form-label">Fetch</label>
                                        <input className='form-control btn btn-soft-success' type='submit' value={'Fetch'} />
                                    </div>
                                </Row>
                            </form>
                            {tableData.length?(
                                <div className='d-none d-md-block'>
                                    <div className='d-flex flex-row justify-content-between'>
                                        <div>
                                            <TableResponsive 
                                                customPageSize = {1000}
                                                isPagination={false} 
                                                isShowingPageLength={false} 
                                                showCustomOptionPage={false} 
                                                showFilter={false} 
                                                noRecord=''
                                                columns={[
                                                    {
                                                        Header: "Name",
                                                        accessor: "first_name",
                                                        HeaderClass:'text-left',
                                                        DataClass:'text-left',
                                                    },
                                                ]}
                                                data={tableData}
                                            />
                                        </div>
                                        <div className='' style={{maxWidth:"1000px",overflowX:'scroll'}}>
                                            <TableResponsive 
                                                customPageSize = {1000}
                                                isPagination={false} 
                                                isShowingPageLength={false} 
                                                showCustomOptionPage={false} 
                                                showFilter={false} 
                                                columns={generatedColumns}
                                                data={tableData}
                                            />
                                        </div>
                                        <div>
                                            <TableResponsive 
                                                customPageSize = {1000}
                                                isPagination={false} 
                                                isShowingPageLength={false} 
                                                showCustomOptionPage={false} 
                                                showFilter={false} 
                                                columns={[
                                                    {
                                                        Header: "Present",
                                                        HeaderClass:'text-center',
                                                        DataClass:'text-center text-success',
                                                        Cell:cell=>{
                                                            const row = cell.row.original;
                                                            return row.attendance?.reduce((total,att)=>att.status === "P"?total+1:total+0,0)
                                                        }
                                                    },
                                                    {
                                                        Header: "Absent",
                                                        HeaderClass:'text-center',
                                                        DataClass:'text-center text-danger',
                                                        Cell:cell=>{
                                                            const row = cell.row.original;
                                                            return row.attendance?.reduce((total,att)=>att.status === "A"?total+1:total+0,0)
                                                        }
                                                    },
                                                    {
                                                        Header: "Leave",
                                                        HeaderClass:'text-center',
                                                        DataClass:'text-center text-warning',
                                                        Cell:cell=>{
                                                            const row = cell.row.original;
                                                            return row.attendance?.reduce((total,att)=>att.status === "L"?total+1:total+0,0)
                                                        }
                                                    }
                                                ]}
                                                data={tableData}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ):(
                                <Row>
                                    <Col>
                                        <Card>
                                            <Card.Body className="d-flex bg-light bg-opacity-50 rounded align-items-center justify-content-center p-5">
                                                <h5 className='text-center'>No Data Found</h5>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            )}
                            <div className='d-lg-none'>

                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}
